<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Based on the information provided below, determine the standard free energy of formation,
        <stemble-latex content="$\Delta\text{G}^\circ_{\text{f}}\text{,}$" />
        of
        <chemical-latex content="H3PO4(l)" />. Note that the
        <stemble-latex content="$\Delta\text{G}^\circ$" />
        values for these reactions have been modified from their actual values so the answer cannot
        simply be found in the table of thermodynamic data.
      </p>

      <v-simple-table class="mb-5">
        <tbody>
          <tr>
            <td>
              <chemical-latex content="P4(s) + 5O2(g) -> P4O10(s)" />
            </td>
            <td>
              <stemble-latex content="$\Delta\text{G}^\circ=$" />
              <number-value :value="deltaG1" unit="\text{kJ/mol}" />
            </td>
          </tr>
          <tr>
            <td>
              <chemical-latex content="2H2(g) + O2(g) -> 2 H2O(g)" />
            </td>
            <td>
              <stemble-latex content="$\Delta\text{G}^\circ=$" />
              <number-value :value="deltaG2" unit="\text{kJ/mol}" />
            </td>
          </tr>
          <tr>
            <td>
              <chemical-latex content="6H2O(g) + P4O10(s) -> 4H3PO4(l)" />
            </td>
            <td>
              <stemble-latex content="$\Delta\text{G}^\circ=$" />
              <number-value :value="deltaG3" unit="\text{kJ/mol}" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <calculation-input
        v-model="inputs.deltaGf"
        class="mb-2"
        dense
        prepend-text="$\Delta\text{G}^\circ_{\text{f}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question298',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        deltaGf: null,
      },
    };
  },
  computed: {
    deltaG1() {
      return this.taskState.getValueBySymbol('deltaG1').content;
    },
    deltaG2() {
      return this.taskState.getValueBySymbol('deltaG2').content;
    },
    deltaG3() {
      return this.taskState.getValueBySymbol('deltaG3').content;
    },
  },
};
</script>
